// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monstechcat-index-js": () => import("../src/pages/monstechcat/index.js" /* webpackChunkName: "component---src-pages-monstechcat-index-js" */),
  "component---src-pages-monstechcat-riot-dogma-resistance-index-js": () => import("../src/pages/monstechcat/riot-dogma-resistance/index.js" /* webpackChunkName: "component---src-pages-monstechcat-riot-dogma-resistance-index-js" */),
  "component---src-pages-monstechcat-uncaged-vol-x-index-js": () => import("../src/pages/monstechcat/uncaged-vol-x/index.js" /* webpackChunkName: "component---src-pages-monstechcat-uncaged-vol-x-index-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

